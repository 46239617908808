import type { NextPage } from "next"
import { useEffect } from "react"
import ModuleList from "src/components/module-list/ModuleList"
import { BaseModule, HeaderStyle, PageReference, PageThemeType, SanityCallToAction, SanityImage, SEOData, DownloadOverlay } from "src/sanity/types"
import { useOverwrites } from "../../layout/page/overwriteContext"

export interface PageDocument {
    id: string
    title: string
    description: string
    slug: {
        current: string
    }
    imageUrl: string
    body: string
    seo: SEOData
    headerStyle: HeaderStyle
    pageThemeType?: PageThemeType
    backgroundImage?: SanityImage
    headerCTAOverwrite: SanityCallToAction
    modules: BaseModule[]
    refs: PageReference[]
    downloadOverlay: DownloadOverlay
    updatedAt?: string
}

const Template: NextPage<{ pageData: PageDocument }> = ({ pageData }) => {
    const { headerDownloadCTA, setDownloadOverlay, resetDownloadOverlay } = useOverwrites()

    useEffect(() => {
        if (pageData.headerCTAOverwrite?.style) {
            headerDownloadCTA.setCTA(pageData.headerCTAOverwrite)
        } else {
            headerDownloadCTA.resetCTA()
        }
    }, [])

    useEffect(() => {
        if (pageData.downloadOverlay) {
            setDownloadOverlay(pageData.downloadOverlay)
        } else {
            resetDownloadOverlay()
        }
    }, [pageData.downloadOverlay, setDownloadOverlay, resetDownloadOverlay])

    return (
        <>
            <ModuleList modules={pageData?.modules} pageThemeType={pageData?.pageThemeType} pageSlug={pageData?.slug?.current} updatedAt={pageData.updatedAt} />
        </>
    )
}

export default Template
